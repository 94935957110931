export class BackgroundAnimation {
  constructor(canvasId, imageUrl) {
    this.canvas = document.getElementById(canvasId);
    this.ctx = this.canvas.getContext('2d');
    this.backgroundImage = new Image();
    this.backgroundImage.onload = () => {
      console.log('Image loaded!');
      console.log('this.backgroundImage.width = ' + this.backgroundImage.width);
      console.log('this.backgroundImage = ' + this.backgroundImage);
    };
    this.backgroundImage.src = imageUrl;
    this.backgroundPosition = 0;
  }

  drawBackground() {
    const canvasWidth = this.canvas.width;
    const canvasHeight = this.canvas.height;
    const backgroundImageWidth = this.backgroundImage.width;
    const backgroundImageHeight = this.backgroundImage.height;
  
    // Calculate the aspect ratio of the background image
    const aspectRatio = backgroundImageWidth / backgroundImageHeight;
  
    //const aspectRatio = 1;

    // Calculate the scaled width and height to fit the canvas while maintaining aspect ratio
    let scaledWidth = canvasWidth;
    let scaledHeight = canvasWidth / aspectRatio;
  
    // If the scaled height exceeds the canvas height, scale based on height instead
    if (scaledHeight > canvasHeight) {
      scaledHeight = canvasHeight;
      scaledWidth = canvasHeight * aspectRatio;
    }

    if (this.backgroundPosition < -scaledWidth) {
      // Move the background to the right side of the canvas
      this.backgroundPosition += scaledWidth;
    }
  
  
    // Draw the background image with scaled dimensions
    let x = this.backgroundPosition;
    let y = Math.abs(scaledHeight - canvasHeight);
/* 
    console.log('aspectRatio = ' + aspectRatio);
    console.log('scaledWidth = ' + scaledWidth);
    console.log('canvasWidth = ' + canvasWidth); */

    for (let i = -1; i < canvasWidth; i += scaledWidth) {
      this.ctx.drawImage(this.backgroundImage, x + Math.floor(i), y, scaledWidth, scaledHeight);
      this.ctx.drawImage(this.backgroundImage, x + Math.floor(i * 2), y, scaledWidth, scaledHeight);
      this.ctx.drawImage(this.backgroundImage, x + Math.floor(i * 3), y, scaledWidth, scaledHeight);
    }
  }

  update() {
    this.backgroundPosition -= 1; // Adjust speed as needed
    this.drawBackground();
  }
}
