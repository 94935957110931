export function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function setHeightMobileCompatible() {
    let contentDOM = document.getElementById('content')
    const vh = window.innerHeight * 0.01;             
    contentDOM.style.setProperty('--vh', `${vh}px`); 
}

let fpsValues = []; // Array to store FPS values
const averagePeriod = 60 * 5; // Number of frames to use for averaging
let lastCalledTime = 0;
let fps;

export function getAvgFPS() {
    // FPS counter
    if (!lastCalledTime) {
        lastCalledTime = performance.now();
        fps = 0;
    }

    let delta = (performance.now() - lastCalledTime) / 1000;
    // if (delta > 0) { // Ensure delta is greater than zero
        lastCalledTime = performance.now();
        fps = 1 / delta;

        // Add current FPS to the array
        fpsValues.push(fps);

        // Keep only the last 'averagePeriod' FPS values
        if (fpsValues.length > averagePeriod) {
            fpsValues.shift(); // Remove the oldest FPS value
        }
    // }

    // Calculate the average FPS
    let sum = 0;
    for (let i = 0; i < fpsValues.length; i++) {
        sum += fpsValues[i];
    }
    let averageFPS = sum / fpsValues.length;

    return Math.round((averageFPS - Math.round(averageFPS)) * 100)/100 + Math.round(averageFPS);
}