import React, { useState } from 'react';
import { Tabs, rem } from '@mantine/core';
import { getUniqItemOwn, addUniqItem } from './js/canvas';
import { buyableItems } from './js/data';

const Shop = () => {
    const [ownedItems, setOwnedItems] = useState(buyableItems.filter(item => getUniqItemOwn()[item.name]));
    const [notOwnedItems, setNotOwnedItems] = useState(buyableItems.filter(item => !getUniqItemOwn()[item.name]));

    const handleClick = (name) => {
        addUniqItem(name);
        const updatedNotOwnedItems = notOwnedItems.filter(item => item.name !== name);
        const boughtItem = buyableItems.find(item => item.name === name);
        setOwnedItems([...ownedItems, boughtItem]);
        setNotOwnedItems(updatedNotOwnedItems);
    }

    return (
        <div className='shop'>
            <div className='shop-title'>Buy</div>
            <div className='shop-content'>
                
                {/* Render not owned items first */}
                {notOwnedItems.map((item, index) => (
                    <div key={index} className='shop-item' onClick={() => handleClick(item.name)}>
                        <img src={item.file_name} alt={item.name} height={32} />
                        <h3>{item.name}</h3>
                        <p>{item.description}</p>
                        <p>Price: {item.price} apples</p>
                    </div>
                ))}
            </div>
            <div className='shop-title'>Already own</div>
            <div className='shop-content'>
                {/* Render owned items */}
                {ownedItems.map((item, index) => (
                    <div key={index} className='shop-item owned'>
                        <img src={item.file_name} alt={item.name} height={32} />
                        <h3>{item.name}</h3>
                        <p>{item.description}</p>
                        <p>Price: {item.price} apples</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Shop;