import ApplePNG from './sprites/apple.png';
import CoinPNG from './sprites/bitcoin.png';
import ScrollPNG from './sprites/scroll.png';

import pillPNG from './sprites/pill.png';
import medkitPNG from './sprites/medicalkit.png';
import featherPNG from './sprites/feather.png';

// Define JSON data for item
export const itemData = [
    {
        name: 'Apple',
        spriteUrl: ApplePNG,
        spriteNb: 1,
        sizeMult: 1.70,
        animationSpeed: 1,
        itemSpeed: 1,
        y: -2
    },
    {
        name: 'Coin',
        spriteUrl: CoinPNG,
        spriteNb: 6,
        sizeMult: 3,
        animationSpeed: 5,
        itemSpeed: 1,
        y: -20
    },
    {
        name: 'Scroll',
        spriteUrl: ScrollPNG,
        spriteNb: 1,
        sizeMult: 1.70,
        animationSpeed: 1,
        itemSpeed: 1,
        y: -4
    },
  ];


// Define a JSON list of buyable items
export const buyableItems = [
    {
        name: 'Apple',
        description: 'A juicy apple.',
        file_name: ApplePNG,
        price: 1, // Price in number of apples
        speed_apple: 1,
        speed_coin: 1,
    },
    {
        name: 'Pill',
        description: 'A magical pill.',
        file_name: pillPNG,
        price: 1, // Price in number of apples
        speed_apple: 2,
        speed_coin: 1,
    },
    {
        name: 'Medical Kit',
        description: 'A medical kit.',
        file_name: medkitPNG,
        price: 5, // Price in number of apples
        speed_apple: 2,
        speed_coin: 1,
    },
    {
        name: 'Feather',
        description: 'A lightweight fire feather.',
        file_name: featherPNG,
        price: 500, // Price in number of apples
        speed_apple: 1,
        speed_coin: 2,
    },
];