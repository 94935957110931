import React, { useState, useEffect } from 'react';
import Slot from './Slot';

const Slots = (props) => {
    const [values, setValues] = useState([0, 1, 2]);
    const nbSlots = 3;

    useEffect(() => {
        if (props.values[0] != -1) {
             /*        
            if (values[0] == values[2]) {
      
                setValues([values[0], values[2], values[1]]);
            }
            if (values[1] == values[2]) {
   
                setValues([values[2], values[1], values[0]]);
            } */
            setValues((prev) => prev.map((value, index) => value - (value % nbSlots) - props.values[index] - 9 - nbSlots % 100));
      }
    }, [props.values]);

    return (
        <>
            <Slot value={values[0]} id={2} />
            <Slot value={values[1]} id={1} />
            <Slot value={values[2]} id={0} />
        </>
    );
};

export default Slots;
