import React, { useState, useEffect } from 'react';
import { getUniqItemOwn } from './js/canvas';
import { buyableItems } from './js/data';

const Inventory = () => {
    const [ownedItems, setOwnedItems] = useState(buyableItems.filter(item => getUniqItemOwn()[item.name]));

    function calcWidth() {
        let width = window.innerWidth / 12;
        width = (width > 42) ? 42 : width;
        return width;
    }
    
return (<div className='inventory'>
        {ownedItems.map((item, index) => (
            <div key={index} className='inventory-item owned'>
                <img src={item.file_name} alt={item.name} width={calcWidth()} />
            {/*  <h3>{item.name}</h3>
                <p>{item.description}</p>
                <p>Price: {item.price} apples</p> */}
            </div>
        ))}
</div>);


};

export default Inventory;
