export class Sprite {
    constructor(canvasId, imageUrl) {
        this.canvas = document.getElementById(canvasId);
        this.ctx = this.canvas.getContext('2d');
    
        this.image = new Image();
        this.image.onload = () => {
        /*   console.log('Image PIG loaded!');
          console.log('this.image.width = ' + this.image.width);
          console.log('this.image = ' + this.image); */
        };
        this.image.src = imageUrl;
        this.animationSpeedDefault = 20;
        this.speedCounter = 0;
        this.spriteIndex = 0;

        // default value can be change from parent
        this.x = 0;
        this.y = 0;
        this.animationSpeed = 1;
      }
    
      update() {
        const canvasWidth = this.canvas.width;
        const canvasHeight = this.canvas.height;
        const imageWidth = this.image.width;
        const imageHeight = this.image.height;
        const spriteWidth = imageWidth / this.spriteNb; // Width of each sprite
        const spriteHeight = imageHeight; // Height of each sprite
        const speedRatio = Math.floor(this.animationSpeedDefault / this.animationSpeed);
        
        this.destHeight = canvasHeight / 10 * this.sizeMult;
        this.destWidth =  spriteWidth * (this.destHeight/imageHeight);

     /*    console.log('speedRatio =' + speedRatio)
        console.log('this.animationSpeedDefault =' + this.animationSpeedDefault)
        console.log('this.animationSpeed =' + this.animationSpeed) */
        
        let x = this.x;
        let y;
        if (this.y < 0) {
            y = Math.abs(canvasHeight - this.destHeight) - this.y;
        } else {
            y = this.y;
        }
    
        this.ctx.drawImage(this.image, this.spriteIndex * spriteWidth, 0, spriteWidth, spriteHeight, x, y, this.destWidth, this.destHeight);
      
        this.speedCounter += 1;
        if (this.speedCounter == speedRatio) {
          this.spriteIndex += 1;
          this.speedCounter = 0;
        }
        if (this.spriteIndex >= this.spriteNb) this.spriteIndex = 0;
      }
}