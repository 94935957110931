// Slot.js
import React, { useState, useEffect } from 'react';
import scoreSprite from './js/sprites/slot_beta.png'; // Adjust the import path

const Slot = (props) => {
    const [backgroundPosition, setBackgroundPosition] = useState(props.value);
    const spriteWidth = 42; // Width of each sprite
    const spriteHeight = 42;
    console.log('Slot window.innerWidth= ' + window.innerWidth);
    let sizePercentage = (window.innerWidth > 768 ? 768 : window.innerWidth) / 5;
    sizePercentage = Math.round(sizePercentage);
    sizePercentage += sizePercentage % spriteWidth;
    sizePercentage = (sizePercentage > 140) ? 140 : sizePercentage;
    const id = props.id;

    useEffect(() => {
      // console.log(props.value);
      setBackgroundPosition(props.value);
    }, [props.value]);

    const containerStyle = {
        width: sizePercentage,//`${spriteWidth / 16 * sizeMultiplicator}em`, // Set a larger width to show the sprite bigger
        height: sizePercentage,//`${spriteHeight / 16 * sizeMultiplicator}em`, // Set a larger height to show the sprite bigger
        overflow: 'hidden',
        background: `linear-gradient(rgb(241 184 195), rgb(0 124 173))`,
        margin: '0.25em',
        borderRadius: ' 0 0 45% 45%',
        border: '0.30em solid rgb(82 103 135)',
      };

    const slotStyle = {
      width: '100%', // Adjust the width as needed
      height: `100%`, // Full viewport height
      backgroundImage: `url('${scoreSprite}')`,
      backgroundRepeat: 'no-repeat',
      backgroundRepeat: 'repeat-y', // Repeat horizontally
      backgroundPosition: `0 ${backgroundPosition}00%`, // Dynamic background position
      transition: `background-position ${3-(id)}s ease-in-out`, // Smooth transition
      backgroundSize: 'cover',
      imageRendering: 'pixelated',
      };
    
      return (
        <div style={containerStyle}>
            <div style={slotStyle}>

            </div>
      </div>
      );
};

export default Slot;
