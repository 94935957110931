import ApplePNG from './sprites/apple.png';
import CoinPNG from './sprites/bitcoin.png';
import ScrollPNG from './sprites/scroll.png';
import { Sprite } from './Sprite';
import { buyableItems } from './data';

export class InterfaceCanvas {
    constructor(canvasId, dataSave) {
        // set context and canvas
        this.canvas = document.getElementById(canvasId);
        this.ctx = this.canvas.getContext('2d');

        // set data
        this.myHero = dataSave.currentHero;
        console.log('myHero.speed = ' + this.myHero.speed);
        this.appleCount = 1000;
        this.coinCount = 1000;
        this.scrollCount = 0;
        this.items = Array(); // for exemple: this.items['Medical Kit'] = 1

        // count item + sprites
        this.appleImg = new Image();
        this.appleImg.src = ApplePNG;

        this.coinImg = new Sprite('canvas1', CoinPNG);
        this.coinImg.spriteNb = 6;
        this.coinImg.sizeMult = 1;
        this.coinImg.x = -5;
        this.coinImg.y = 25;

        this.scrollImg = new Image();
        this.scrollImg.src = ScrollPNG;

        // Preload images
        this.images = {};
        buyableItems.forEach(item => {
            const img = new Image();
            img.src = item.file_name;
            this.images[item.name] = img;
        });
    }

    update() {
     /*    const canvasWidth = this.canvas.width;
        const canvasHeight = this.canvas.height; */
        const imageWidth = this.appleImg.width;
        const imageHeight = this.appleImg.height;

        this.ctx.drawImage(this.appleImg, 0, 0, imageWidth, imageHeight);
        this.ctx.font = "bold 1.25rem Verdana"; // Use bold and rem for font
        this.ctx.fillStyle = `rgba(0, 0, 0)`; 
        this.ctx.fillText(this.appleCount, imageWidth + 5, imageHeight);

        this.ctx.fillText(this.coinCount, imageWidth + 5, imageHeight * 2);
        this.coinImg.update();

        this.ctx.drawImage(this.scrollImg, -2, 50 +3, this.scrollImg.width, this.scrollImg.height);
        this.ctx.fillText(this.scrollCount, imageWidth + 5, imageHeight * 3);

  /*       let i = 1;
        Object.entries(this.items).forEach(([name, nb]) => {
            if (nb > 0) {
                buyableItems.forEach(item => {
                    if (item.name === name) {
                        const img = this.images[name];
                        this.ctx.drawImage(img, this.canvas.width - 30 * i, 0); // Adjust the position as needed
                        i++;
                    }
                });
            }
        }); */
    }
}
