// App.js
import React, { useState, useEffect, useController, useLayoutEffect } from 'react';
import { notifications } from '@mantine/notifications';
import { Progress } from '@mantine/core';
import { AwesomeButton, AwesomeButtonProgress } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';
import './buttons.css';
import {decCoin, addCoin, addRoad, addScroll, firstRoutine, addNotification } from './js/canvas';
import { buyableItems } from './js/data';
import Slots from './Slots';
import Shop from './Shop';
import Inventory from './Inventory';
import font from './js/sprites/font.png';

const App = () => {
  const [slotValues, setSlotValues] = useState([-1, -1, -1]);
  const [shopOpen, setShopOpen] = useState(true); // Initialize shopOpen state to false

  useEffect(() => {
    const handleLoad = () => {
      // Perform actions after the component has fully loaded
      firstRoutine();
    };
    if (document.readyState === "complete") {
      firstRoutine();
    }
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

 
  // game stuff
  const handleOpenShop = () => {
    setShopOpen(false); // Update shopOpen state to false when the close shop button is clicked
  };
  
  const handleCloseShop = () => {
    setSlotValues([-1, -1, -1]);
    setShopOpen(true); // Update shopOpen state to true when the shop button is clicked
  };

  const rollSlots = (next) => {
  
    if (decCoin()) {
      // Update the slot values with random numbers between 0 and 2
      const newValues = slotValues.map((prev) => Math.floor(Math.random() * 3));
      setSlotValues(newValues);
      console.log("press" + newValues);
        setTimeout(() => {
          if (newValues[0] == newValues[1] && newValues[1] == newValues[2]) {
            addNotification('Win ' + 90 + ' apples!');
            addRoad('Apple', 90);
          }
          next();
        }, 3000);
    } else {
      next();
      notifications.show({
        title: 'Sorry!',
        message: 'You need to buy 1 coin to roll',
        color: 'red',
      });
      addNotification('You need to buy 1 coin to roll')
    }
  };


 /*  const styleRoot = {
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: 'rgb(228 206 231)',
  }

  const styleMain = {
    display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
    width: 'calc(100vw)',
    height: 'calc(95vh)',
    maxWidth: '768px', 
    alignItems: 'center'
  }

  const buttonContainer = {
      display: 'flex',
      flexDirection: 'row',
      width: '100vw',
      justifyContent: 'space-evenly',
      
  } */

  const contentStyle = {
    backgroundImage: `url(${font})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  }

  return (
    <div id='content' className='content' style={contentStyle}>
      <div className='screen'>
        <canvas id='canvas1' className='canvas1'></canvas>
        <div className='barxp'>
         <Progress size="lg" color="teal" value={60} />
        </div>
        <div className='slots'>
          <Slots values={slotValues}></Slots>
        </div>
      </div>
        {/* Conditionally render buttonsInterface based on the shopOpen state */}
        {shopOpen ? (<>
      
          <div className='buttonsInterface'>
        <AwesomeButtonProgress
          type="secondary"
          // size="medium"
          loadingLabel='Rolling...'
          resultLabel='!!!'
          releaseDelay='100'
          onPress={(event, release) => {
            rollSlots(release);
          }}
        >
          Roll
        </AwesomeButtonProgress>
      </div>
        <Inventory />
        <div className='buttonsInterface'>
          <AwesomeButton type='secondary' onPress={addCoin}>Buy Coin</AwesomeButton>
          <AwesomeButton type='secondary' onPress={addScroll}>Buy Scroll</AwesomeButton>
          <AwesomeButton type='secondary' onPress={handleOpenShop}>Open shop</AwesomeButton>
        </div>
        </>
      ) : (<>
          <Shop></Shop>
        <div className='buttonsInterface'>
          <AwesomeButton type='secondary' onPress={handleCloseShop}>Close shop</AwesomeButton>
        </div>
        </>
      )}
    </div>
  );
};
export default App;
