// Import necessary modules
import { Sprite } from './Sprite';
import PigPNG from './sprites/pig_sprite.png';

// Define JSON data for heroes
const heroData = [
  {
      name: 'Pig',
      spriteUrl: PigPNG,
      spriteNb: 3,
      sizeMult: 3,
      animationSpeed: 1,
      appleSpeed: 1,
      x: 15,
      y: -10
  },
  {
     
  },
  // Add more hero data as needed
];

export class Hero extends Sprite {
  constructor(canvasId, heroName) {
      // Find the hero data by name
      const hero = heroData.find(hero => hero.name === heroName);
      if (!hero) {
          throw new Error(`Hero '${heroName}' not found in heroData.`);
      }

      // Call the superclass constructor with sprite URL
      super(canvasId, hero.spriteUrl);

      // Set additional attributes based on hero data
      this.spriteNb = hero.spriteNb; // Set the specific sprite number for pig animation
      this.sizeMult = hero.sizeMult;
      this.x = hero.x;
      this.y = hero.y;
      this.animationSpeed = hero.animationSpeed;
      this.appleSpeed = hero.appleSpeed;
  }
}