import { Sprite } from './Sprite';
import { itemData} from './data';

export class ItemRoad extends Sprite {
    constructor(canvasId, itemName) {
        // Find the item data by name
        const item = itemData.find(item => item.name === itemName);
        if (!item) {
            throw new Error(`Item '${itemName}' not found in itemData.`);
        }

        super(canvasId, item.spriteUrl);

        this.canvas = document.getElementById(canvasId);
     
        this.name = item.name;
        this.image = new Image();
        this.image.src = item.spriteUrl;
        this.spriteNb = item.spriteNb;
        this.animationSpeed = item.animationSpeed;
        this.spriteIndex = 0;
        this.speedRatio = 20;
        this.speedCounter = 0;
        this.sizeMult = item.sizeMult;
        this.free = 0;
        this.x = this.canvas.width;
        this.y = item.y; //Math.abs(canvasHeight - imageHeight * this.sizeMult);
    }

   /*  update() {
        const canvasWidth = this.canvas.width;
        const canvasHeight = this.canvas.height;
        const imageWidth = this.image.width;
        const imageHeight = this.image.height;
        const spriteWidth = imageWidth / this.spriteNb; // Width of each sprite
        const spriteHeight = imageHeight; // Height of each sprite

        let y = Math.abs(canvasHeight - imageHeight * this.sizeMult);

        this.ctx.drawImage(this.image, this.x, y, spriteWidth * this.sizeMult , spriteHeight * this.sizeMult);
        this.x -= 7;
    } */
}