import { getRandomInt, setHeightMobileCompatible, getAvgFPS } from '../utils.js';
import { BackgroundAnimation } from './canvas_background.js';
import { Hero } from './Hero.js';
import { InterfaceCanvas } from './interfaceCanvas.js';
import { ItemRoad } from './itemRoad.js';
import { notifications } from '@mantine/notifications';
import BackgroundPNG from './sprites/MushroomShrineBackground.png';
import { buyableItems } from './data.js';


console.log('canvas.js');

// Objects to animate
let animateObjectArray = [];
let itemToAddOnRoad = []
let interfaceCanvas;
let totalSpeedApple;
let totalSpeedCoin;
let msgNotif = [];

/* if (document.readyState === "complete") {
    firstRoutine();
} else {
    window.addEventListener('load', function(){
        firstRoutine();
    });
}
 */
export function firstRoutine() {
    animateObjectArray.push(new BackgroundAnimation('canvas1', BackgroundPNG));
    const myHero = new Hero('canvas1', 'Pig');
    animateObjectArray.push(myHero);
    const dataSave = { currentHero: myHero };
    interfaceCanvas = new InterfaceCanvas('canvas1', dataSave);
    animateObjectArray.push(interfaceCanvas);

    const speeds = countSpeeds();
    totalSpeedApple = speeds.totalSpeedApple;
    totalSpeedCoin = speeds.totalSpeedCoin;
    animateLoop();
    addRoad('Apple', 1);
}

let counterFrame = 0;
let lastFrameTime = 0;
const frameInterval = 1000 / 60; // 60fps
function animateLoop() {
    const currentTime = performance.now();
    const elapsedTime = currentTime - lastFrameTime;

    if (elapsedTime > frameInterval) {
        // KISS resize
        const canvas = document.getElementById('canvas1');
        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = false;
        let width = window.innerWidth > 768 ? 768 : window.innerWidth;
        let height = Math.floor(window.innerHeight * 0.33);
        if (canvas.width != width) {
            setHeightMobileCompatible();
            canvas.width = width; console.log('trig resize width');
        }
        if (canvas.height != height) { 
            setHeightMobileCompatible();
            canvas.height = height;  console.log('trig resize height');
        }

        const appleInterval = 60 / totalSpeedApple; // Interval for adding Apple (in milliseconds)
        const coinInterval = 600 / totalSpeedCoin; // Interval for adding Coin (in milliseconds)

        counterFrame += 1;
          // Add Coin every 10 seconds
          if (!(counterFrame % Math.round(coinInterval))) {
            addRoad('Coin', 1);
        }
        // Add Apple once per second
        if (!(counterFrame % Math.round(appleInterval))) {
            addRoad('Apple', 1);
        }
    
        let itemName = itemToAddOnRoad.pop();
        if (itemName)
            animateObjectArray.push(new ItemRoad('canvas1', itemName));
        calcCollision();
        animateObjectArray = animateObjectArray.filter(object => !object.free); // delete free items
        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        animateObjectArray.forEach(object => object.update()); // animation for each object
        let fps = getAvgFPS();
        ctx.font = "1rem Verdana";
        ctx.fillStyle = `rgba(0, 0, 0)`; 
        ctx.fillText('FPS=' + fps, canvas.width - 100, 20);
        drawNotifications(ctx, canvas);

        // limit FPS
        lastFrameTime = currentTime - (elapsedTime % frameInterval);
    }
    requestAnimationFrame(animateLoop);
}

function drawNotifications(ctx, canvas) {
    const fadeSpeed = 0.005; // Adjust fade speed as needed
    const messageColor = 'rgba(0, 0, 0, 1)'; // Yellow color with maximum opacity
    const borderColor = 'rgba(0, 0, 0, 1)'; // Black color for the message border

    ctx.font = '0.75rem'; // Adjust font size and style as needed
    ctx.textBaseline = 'bottom'; // Align text to the bottom

    for (let i = 0; i < msgNotif.length; i++) {
        const message = msgNotif[i];
        msgNotif[i].opacity -= fadeSpeed * i; // Reduce opacity of the message
        if (msgNotif[i].opacity <= 0) {
            msgNotif.splice(i, 1); // Remove faded messages from the array
            i--; // Adjust loop index to account for removed item
            continue; // Skip drawing the message if it has faded completely
        }
        ctx.globalAlpha = msgNotif[i].opacity; // Set global alpha for transparency
        ctx.fillStyle = borderColor; // Set text color
        ctx.font = '1rem Verdana';;
        // ctx.strokeText(message.text, canvas.width / 2, 0 + lineHeight * (1 + i)); // Draw message
        ctx.fillStyle = messageColor; // Set text color
        let textWidth = ctx.measureText(message.text).width;
        ctx.fillText(message.text, canvas.width / 2 - textWidth / 2, 0 + 20 * (1 + i)); // Draw message
    }
    ctx.globalAlpha = 1; // Reset global alpha
}

export function addNotification(message) {
    const maxMessages = 10;

    let msg = { text: message, opacity: 1 }; // Initialize message object
    msgNotif.unshift(msg); // Add new message to the beginning of the array
    // Limit the number of messages if needed
    if (msgNotif.length > maxMessages) {
        msgNotif.pop(); // Remove the last message if the array exceeds the limit
    }
}

function calcCollision() {
    animateObjectArray.forEach(character => {
        if (character instanceof Hero) {
            animateObjectArray.forEach(otherObject => {
                if (otherObject instanceof ItemRoad) {
                    otherObject.x -= 7;
                    const heroRight = character.x + character.destWidth;
                    const roadItemLeft = otherObject.x;
                    if (heroRight >= roadItemLeft) {
                        // Collision detected, handle it here
                        otherObject.free = true;
                        if (otherObject.name == 'Apple') {
                            interfaceCanvas.appleCount++;
                        }
                        if (otherObject.name == 'Coin') {
                            interfaceCanvas.coinCount++;
                        }
                        if (otherObject.name == 'Scroll') {
                            interfaceCanvas.scrollCount++;
                        }
                    }
                }
            });
        }
    });
}

export function addRoad(itemString, nb) {
    while (nb--)
        itemToAddOnRoad.push(itemString);
}

export function decCoin() {
    if (interfaceCanvas.coinCount > 0) {
        interfaceCanvas.coinCount--;
        return true;
    } 
    return false;
}

export function addCoin() {
    if (interfaceCanvas.appleCount >= 10) {
        addNotification("Coin +1")
        // interfaceCanvas.coinCount++;
        addRoad('Coin', 1)
        interfaceCanvas.appleCount -= 10;
    } else {
       /*  notifications.show({
            title: 'Not enough apple!',
            message: 'You need 10 apples to buy a coin.',
            color: 'red',
          }); */
          addNotification('You need 10 apples to buy a coin.')
    }
}

export function addScroll() {
    if (interfaceCanvas.appleCount >= 20) {
        addNotification("Scroll +1")
        itemToAddOnRoad.push('Scroll');
        interfaceCanvas.appleCount -= 20;
    } else {
    /* notifications.show({
        title: 'Not enough apple!',
        message: 'You need 20 apples to buy a scroll.',
        color: 'red',
        }); */
        addNotification('You need 20 apples to buy a scroll.')
    }
}

export function addUniqItem(name) {
    // console.log('addUniqItem = ' + name);
    const item = buyableItems.find(item => item.name === name);
    // console.log('item = ' + item + ', price = ' + item.price);
    // console.log('applcount = ' + interfaceCanvas.appleCount);
    // console.log('interfaceCanvas.items[name] = ' + interfaceCanvas.items[name]);
    if (item && interfaceCanvas.appleCount >= item.price && interfaceCanvas.items[name] != 1) {
        interfaceCanvas.items[name] = 1;
        interfaceCanvas.appleCount -= item.price;
        addNotification('You just buy ' + name);
        notifications.show({
            title: 'New item bought!',
            message: 'You just buy ' + name,
            // color: 'red',
          });
    }
    const speeds = countSpeeds();
    totalSpeedApple = speeds.totalSpeedApple;
    totalSpeedCoin = speeds.totalSpeedCoin;
}

export function getUniqItemOwn() {
    console.log('getUniqItemOwn = ' + interfaceCanvas);
    if (interfaceCanvas?.items) {
        return interfaceCanvas.items;
    } else {
        return [];
    }
}

function countSpeeds() {
    let totalSpeedApple = 1;
    let totalSpeedCoin = 1;

    Object.keys(interfaceCanvas.items).forEach(name => {
        const item = buyableItems.find(item => item.name === name);
        if (item) {
            totalSpeedApple *= item.speed_apple || 1;
            totalSpeedCoin *= item.speed_coin || 1;
        }
    });

    return { totalSpeedApple, totalSpeedCoin };
}

